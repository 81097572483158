// @flow

import { Card, Col, Row } from 'antd';
import React, {Fragment} from 'react';

import AntdContainer from '../components/AntdContainer';
import HtmlHead from '../components/HtmlHead';
import Img from "gatsby-image";
import Link from '../components/Link';
import {graphql} from 'gatsby';

const { Meta } = Card;

export const query = graphql`
  query BlogQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          excerptImg {
            fluid(maxWidth: 600, maxHeight: 400) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const BlogView = ({ data }) => {

  return (
    <div className='section light'>
      <HtmlHead title='Blog | PostSeal' />
      <div className='content-wrapper'>
        <AntdContainer>
          <h1 className='ant-typography page-title'>Blog</h1>
          <Row gutter={24}>
            {
              data.allContentfulBlogPost.edges.map(({node}) => {
                const fullSlug = `/blog/${node.slug}`;
                return (
                  <Fragment key={fullSlug}>
                    {
                      // <Col xs={{span: 6}}>
                      //   <Img fluid={node.excerptImg.fluid} />
                      // </Col>
                      // <Col xs={{span: 16, offset: 2}}>
                      //   <Link to={fullSlug}><h2>{node.title}</h2></Link>
                      //   <p>{node.excerpt.excerpt}</p>
                      // </Col>
                    }

                    {
                      <Col xs={24} md={{span: 12}} className='bottom-space-4x'>
                        <Link to={fullSlug}>
                          <Card hoverable cover={<Img fluid={node.excerptImg.fluid} />}>
                            <Meta title={node.title} />
                          </Card>
                        </Link>
                      </Col>
                    }
                  </Fragment>
                );
              })
            }
          </Row>
        </AntdContainer>
      </div>
    </div>
  );
}

export default BlogView;
